import { useId } from "@reach/auto-id";
import Creatable, { CreatableProps } from "react-select/creatable";
import { GroupBase } from "react-select";
import Control from "./Control";
import DropdownWrapper from "./DropdownWrapper";

interface CreatableDropdownProps<
  Option,
  isMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
> extends CreatableProps<Option, isMulti, Group> {
  label: string;
  errorMessage?: string;
  required?: boolean;
  hideError?: boolean;
}

function CreatableDropdown<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  label,
  required,
  errorMessage,
  hideError = false,
  ...rest
}: CreatableDropdownProps<Option, IsMulti, Group>) {
  const inputId = useId();
  const errorId = `error-${inputId}`;

  return (
    <DropdownWrapper
      label={label}
      required={required}
      errorMessage={errorMessage}
      hideError={hideError}
      errorId={errorId}
    >
      <Creatable
        {...rest}
        components={{ Control }}
        aria-errormessage={errorId}
        aria-invalid={!!errorMessage}
      />
    </DropdownWrapper>
  );
}

export default CreatableDropdown;

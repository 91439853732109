import Link from "next/link";
import { Settings, Calendar, LogOut } from "react-feather";

import { useUserContext } from "components/GlobalContexts/UserContext";
import Popover from "components/Popover";
import OrganizationSwitch from "components/OrganizationSwitch";
import useLogoutUser from "hooks/useLogoutUser";
import Avatar from "components/People/Avatar";
import { IS_TABLET_OR_SMALLER_MEDIA_QUERY } from "constants/screen";
import useMatchMedia from "hooks/useMatchMedia";

const HEADING_CLASS =
  "bg-dark-blue-10 rounded-l-large text-left ml-8 pl-16 flex items-center h-[28px]";

const AccountPopover = ({ onClose }: { onClose: Function }) => {
  const { myAppUser } = useUserContext();
  const handleLogout = useLogoutUser();

  const { avatarUrl, name, email } = myAppUser || {};

  const isTablet = useMatchMedia(IS_TABLET_OR_SMALLER_MEDIA_QUERY);

  return (
    <Popover
      onClose={onClose}
      pointyClassNames={
        isTablet ? "bottom-[76px] right-[32px]" : "bottom-[40px] left-[92px]"
      }
      popoverClassNames={
        isTablet ? "bottom-[82px] right-[5px]" : "bottom-[8px] left-[100px]"
      }
    >
      <div className="text-grey--50">
        <div className="flex flex-row items-center justify-center p-24">
          {name && (
            <Avatar
              name={name}
              imageSize={48}
              avatarUrl={avatarUrl}
              isRounded
              className="min-w-[48px]"
            />
          )}
          <div className="ml-8 flex flex-col">
            <span className="text-left text-t1">{name}</span>
            <span className="text-left">{email}</span>
          </div>
        </div>
        <div className="flex flex-col">
          <h1 className={HEADING_CLASS}>Account</h1>
          <span
            onClick={() => {
              onClose();
            }}
          >
            <Link href="/account/delegation">
              <a>
                <div className="ml-24 mt-16 flex justify-items-start">
                  <Settings className="text-dark-blue-75" />
                  <span className="ml-8">My Account</span>
                </div>
              </a>
            </Link>
          </span>
          <span
            onClick={() => {
              onClose();
            }}
          >
            <Link href="/account/delegation">
              <a>
                <div className="ml-24 mt-16 flex justify-items-start">
                  <Calendar className="text-dark-blue-75" />
                  <span className="ml-8">Temporary Delegation</span>
                </div>
              </a>
            </Link>
          </span>
          {/* eslint-disable-next-line */}
          <a href="/api/auth/logout" onClick={handleLogout}>
            <div className="my-16 ml-24 flex justify-items-start">
              <LogOut className="text-dark-blue-75" />
              <span className="ml-8">Sign Out</span>
            </div>
          </a>
        </div>
        <div className="flex flex-col pb-24">
          <h1 className={HEADING_CLASS}>Organization</h1>
          <span className="mr-24">
            <OrganizationSwitch />
          </span>
        </div>
      </div>
    </Popover>
  );
};

export default AccountPopover;

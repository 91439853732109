import SideNavigation from "components/SideNavigation";

type NavLayoutProps = {
  children?: React.ReactNode;
  current: string;
};

const NavLayout: React.FC<NavLayoutProps> = ({ children, current }) => {
  return (
    <div className="flex h-full flex-col-reverse md:flex-row">
      <SideNavigation current={current} />
      <main className="max-h-full w-full flex-grow overflow-y-auto bg-white sm:bg-grey-5 md:bg-grey--80">
        {children}
      </main>
    </div>
  );
};

export default NavLayout;

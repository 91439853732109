/**
 * Extract initials from text.
 * @param {string} text name of the entity to be extracted
 */
export const getInitials = (text: string): string => {
  const [first, last] = text.split(" ");

  if (first && last) {
    return `${first.charAt(0)}${last.charAt(0)}`.toUpperCase();
  }
  return first.charAt(0).toUpperCase();
};

export const capitalize = (string: string): string => {
  if (string) return string[0].toUpperCase() + string.slice(1);
  return "";
};

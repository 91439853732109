import Label from "components/Form/Label";

import ErrorMessage from "components/Form/ErrorMessage";

export interface DropdownWrapperProps {
  label: string;
  errorMessage?: string;
  required?: boolean;
  hideError?: boolean;
  children: any;
  errorId?: string;
  className?: string;
}

function DropdownWrapper({
  label,
  required,
  errorMessage,
  hideError = false,
  children,
  errorId,
  className,
}: DropdownWrapperProps) {
  return (
    <div className={className}>
      <Label label={label} required={required}>
        {children}
      </Label>
      {!hideError && <ErrorMessage id={errorId}>{errorMessage}</ErrorMessage>}
    </div>
  );
}

export default DropdownWrapper;

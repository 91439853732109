import {
  ChangeEventHandler,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useId } from "@reach/auto-id";
import { Square } from "react-feather";
import { useMergeRefs } from "hooks/useMergeRefs";

import { CheckboxFilled, MinusSquare } from "components/Icons";
import classNames from "classnames";

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  isIndeterminate?: boolean;
}

type CheckboxValues = "on" | "off" | "indeterminate";
const checkboxIcons = Object.freeze({
  on: CheckboxFilled,
  off: Square,
  indeterminate: MinusSquare,
});

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(function Checkbox(
  { children, isIndeterminate = false, onChange, checked, ...rest },
  ref
) {
  const inputId = useId();
  const internalRef = useRef<HTMLInputElement>(null);
  const refs = useMergeRefs<HTMLInputElement | null>(internalRef, ref);
  const [checkboxValue, setCheckboxValue] = useState<CheckboxValues>("off");

  const findValue = useCallback(() => {
    if (isIndeterminate) {
      return "indeterminate";
    }

    const finalChecked = checked ?? internalRef.current?.checked;

    return finalChecked ? "on" : "off";
  }, [isIndeterminate, checked]);

  useEffect(() => {
    setCheckboxValue(findValue());
  }, [findValue]);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    onChange?.(event);
    setCheckboxValue(findValue());
  };

  const Icon = checkboxIcons[checkboxValue];

  return (
    <div className="cursor-not-allowed text-t4">
      <input
        type="checkbox"
        id={inputId}
        className="checkbox sr-only accent-blue-100"
        ref={refs}
        checked={checkboxValue === "on"}
        onChange={handleChange}
        {...rest}
      />
      <label
        className={classNames([
          "flex items-center gap-x-4 text-grey--50",
          {
            "cursor-not-allowed": rest.disabled,
          },
        ])}
        htmlFor={inputId}
      >
        <div aria-hidden="true">
          <Icon size={24} />
        </div>
        <p>{children}</p>
      </label>
    </div>
  );
});

export default Checkbox;

import { useOrganization } from "components/GlobalContexts/OrganizationContext";
import PrimaryOrgSwitcher from "./PrimaryOrgSwitcher";
import TextAvatar from "components/People/Avatar/TextAvatar";
import { useEffect } from "react";

const OrganizationSwitch: React.FC = () => {
  const { organizations, organization, switchOrg, refetchOrgs } =
    useOrganization();

  useEffect(() => {
    refetchOrgs();
  }, [refetchOrgs]);

  return (
    <div>
      {organizations.map(({ id, name, isPrimary }) => {
        const active = id === organization?.id;

        return (
          <div key={id} className="ml-24 mt-16 flex justify-between">
            <div className="flex">
              <span>
                {active ? (
                  <span className="flex">
                    <TextAvatar
                      name={name}
                      imageSize={24}
                      className="rounded-full"
                    />
                    <span className="ml-8 max-w-[150px] truncate font-[900]">
                      {name}
                    </span>
                  </span>
                ) : (
                  <button
                    className="flex"
                    onClick={() => {
                      switchOrg(id);
                    }}
                  >
                    <TextAvatar
                      name={name}
                      imageSize={24}
                      className="rounded-full"
                    />
                    <span className="ml-8 max-w-[150px] truncate font-[100]">
                      {name}
                    </span>
                  </button>
                )}
              </span>
            </div>
            <PrimaryOrgSwitcher checked={!!isPrimary} id={id} />
          </div>
        );
      })}
    </div>
  );
};

export default OrganizationSwitch;

import {
  forwardRef,
  ForwardRefRenderFunction,
  InputHTMLAttributes,
  ReactNode,
} from "react";
import classNames from "classnames";
import { useId } from "@reach/auto-id";
import { get } from "lodash";

import Label from "../Label";
import ErrorMessage from "../ErrorMessage";

type InputSizes = "MEDIUM" | "LARGE";

export interface InputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "size"> {
  label: string;
  value?: string;
  size?: InputSizes;
  /**
   * Commonly used for icon or symbol on left of input
   */
  leftEl?: ReactNode;
  /**
   * Commonly used for icon or symbol on right of the input
   */
  rightEl?: ReactNode;
  /**
   * Name of the input. Also used for displaying error message
   */
  name: string;
  /** The errors object passed on from library like react-hook-form
   * If there is no errors object, pass an object in the form { [name]: "error message to be displayed" }
   */
  errors?: any;
  /**
   * In case you need to render validation message, help text etc.
   * Note that appearance of these elements would disrupt the flow
   * So you probably want to use the visibility trick (as seen in ErrorMessage)
   */
  children?: ReactNode;
}

const Input: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  {
    label,
    value,
    onChange,
    className,
    size = "MEDIUM",
    leftEl,
    rightEl,
    name,
    errors = {},
    disabled,
    children,
    required,
    ...rest
  },
  ref
) => {
  const inputId = useId();
  const errorId = `error-${inputId}`;
  const errorMessage = get(errors, [name, "message"]);

  return (
    <div className={className}>
      <Label label={label} required={required}>
        <div
          className={classNames(
            "input-wrapper flex items-center gap-4 rounded-large bg-white font-medium",
            {
              "px-16 py-8": size === "MEDIUM",
              "p-16": size === "LARGE",
              invalid: !!errorMessage,
              "cursor-not-allowed bg-grey-15 opacity-70": disabled,
            }
          )}
        >
          {leftEl}
          <input
            className={classNames(
              "w-full min-w-0 max-w-full flex-1 text-t4 font-light placeholder:text-grey-75 focus:outline-none"
            )}
            name={name}
            value={value}
            aria-describedby={errorId}
            aria-invalid={!!errorMessage}
            id={inputId}
            disabled={disabled}
            required={required}
            ref={ref}
            onChange={onChange}
            {...rest}
          />
          {rightEl}
        </div>
      </Label>
      {children}
      <ErrorMessage id={errorId}>{errorMessage}</ErrorMessage>
    </div>
  );
};

export default forwardRef<HTMLInputElement, InputProps>(Input);

import classNames from "classnames";
import { ReactNode } from "react";
import {
  Children,
  cloneElement,
  forwardRef,
  ForwardRefRenderFunction,
  isValidElement,
} from "react";
import { UseFormRegisterReturn } from "react-hook-form";

type SelectionListOrientation = "vertical" | "horizontal";

interface SelectionListProps extends Partial<UseFormRegisterReturn> {
  orientation?: SelectionListOrientation;
  className?: string;
  children: ReactNode;
}

/**
 * Helper for container of Selection components - Checkbox and Radio
 * @example
 * <RadioList orientation="vertical" {...register('radioName')}>
 *  <Radio value="1">Option 1</Radio>
 * </RadioList>
 */
const SelectionList: ForwardRefRenderFunction<
  HTMLInputElement,
  SelectionListProps
> = ({ className, orientation = "horizontal", children, ...rest }, ref) => {
  return (
    <div
      className={classNames(
        "flex",
        {
          "gap-x-16": orientation === "horizontal",
          "flex-col gap-y-16": orientation === "vertical",
        },
        className
      )}
    >
      {Children.map(children, (child) => {
        if (isValidElement(child)) {
          return cloneElement(child, { ...rest, ref });
        }
        return child;
      })}
    </div>
  );
};

export default forwardRef<HTMLInputElement, SelectionListProps>(SelectionList);

import { useCallback, useMemo } from "react";
import { useQuery, gql } from "@apollo/client";
import { Control, FieldValues } from "react-hook-form";

import { AppUsersConnection } from "constants/types";
import UserListItem from "components/Form/UserListItem";
import AsyncControlledDropdown from "./AsyncControlledDropdown";
import { AppUser, Maybe } from "constants/types";

const GET_USERS = gql`
  query getUsers($searchText: String!, $offset: Int!, $first: Int!) {
    appUsers(
      offset: $offset
      first: $first
      orderBy: [NAME_ASC]
      filter: { name: { likeInsensitive: $searchText } }
    ) {
      totalCount
      nodes {
        id
        name
        title
        avatarUrl
      }
    }
  }
`;

interface UserDropDownProps<T extends FieldValues = FieldValues> {
  label: string;
  required?: boolean;
  name: string;
  errorMessage?: string;
  disabled?: boolean;
  clearable?: boolean;
  placeholder?: string;
  control: Control<T, object>;
  defaultValue?: any;
  excludedUserIds?: Set<String>;
  className?: string;
  onChange?: (e: any) => void;
  hideError?: boolean;
  closeMenuOnSelect?: boolean;
}

const UserDropdown = <T extends FieldValues = FieldValues>({
  label,
  required = false,
  name,
  errorMessage,
  disabled,
  clearable,
  placeholder = "Select User",
  control,
  defaultValue,
  excludedUserIds = new Set([]),
  onChange,
  hideError,
  closeMenuOnSelect,
  ...rest
}: UserDropDownProps<T>) => {
  const { data, loading, refetch } = useQuery<{ appUsers: AppUsersConnection }>(
    GET_USERS,
    {
      variables: {
        searchText: "%",
        first: 50,
        offset: 0,
      },
    }
  );

  const excludedUserFilter = useCallback(
    (user: Maybe<AppUser>): user is AppUser => !excludedUserIds.has(user?.id),
    [excludedUserIds]
  );

  const defaultOptions = useMemo<AppUser[]>(
    () => data?.appUsers?.nodes.filter(excludedUserFilter) || [],
    [data, excludedUserFilter]
  );

  return (
    <AsyncControlledDropdown
      // control as prop does not pass, probably not worth it
      control={control as any}
      label={label}
      required={required}
      defaultOptions={defaultOptions}
      loadOptions={async (inputValue: string) => {
        const { data } = await refetch({
          searchText: `%${inputValue}%`,
          offset: 0,
          first: 30,
        });
        return data.appUsers.nodes.filter(excludedUserFilter);
      }}
      getOptionValue={(option) => option?.id}
      getOptionLabel={(option) => option?.name || ""}
      formatOptionLabel={(option) =>
        option?.name ? <UserListItem appUser={option} /> : ""
      }
      name={name}
      errorMessage={errorMessage}
      isDisabled={disabled}
      isClearable={clearable}
      placeholder={placeholder}
      defaultValue={defaultValue}
      isLoading={loading}
      onChange={onChange}
      hideError={hideError}
      closeMenuOnSelect={closeMenuOnSelect}
      {...rest}
    />
  );
};

export default UserDropdown;

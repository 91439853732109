import { FC } from "react";
import Avatar from "components/People/Avatar";

interface AccountPill {
  avatarUrl?: string;
  name?: string;
}

const AccountPill: FC<AccountPill> = ({ avatarUrl, name }) => {
  return (
    <div className="h-32">
      {name && (
        <Avatar
          name={name || ""}
          avatarUrl={avatarUrl}
          imageSize={32}
          isRounded
        />
      )}
    </div>
  );
};

export default AccountPill;

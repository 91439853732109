import { useId } from "@reach/auto-id";
import classNames from "classnames";
import { get } from "lodash";
import { forwardRef, TextareaHTMLAttributes } from "react";
import ErrorMessage from "../ErrorMessage";

import Label from "../Label";

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label: string;
  /**
   * Name of the input. Also used for displaying error message
   */
  name: string;
  /** The errors object passed on from library like react-hook-form
   * If there is no errors object, pass an object in the form { [name]: "error message to be displayed" }
   */
  errors?: any;
}

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  function TextArea({ label, required, errors, name, disabled, ...rest }, ref) {
    const inputId = useId();
    const errorId = `error-${inputId}`;
    const errorMessage = get(errors, [name, "message"]);

    return (
      <Label label={label} required={required}>
        <textarea
          className={classNames(
            "input-wrapper w-full min-w-0 max-w-full resize-none rounded-large bg-white px-16 py-8 text-t4 font-light placeholder:text-grey-75 focus:outline-none",
            {
              invalid: !!errorMessage,
              "cursor-not-allowed bg-grey-15 opacity-70": disabled,
            }
          )}
          name={name}
          aria-describedby={errorId}
          aria-invalid={!!errorMessage}
          id={inputId}
          disabled={disabled}
          required={required}
          ref={ref}
          {...rest}
        />
        <ErrorMessage id={errorId}>{errorMessage}</ErrorMessage>
      </Label>
    );
  }
);

export default TextArea;

import classNames from "classnames";
import Image from "next/image";
import { useRouter } from "next/router";

import { Alert } from "constants/types";
import Avatar from "components/People/Avatar";

interface AlertItemProps {
  alert: Alert;
  onClick: () => void;
}

const AlertItem: React.FC<AlertItemProps> = ({ alert, onClick }) => {
  const { push } = useRouter();

  const { isRead, title, imageType, avatarUser, iconPath, clickPath } =
    alert || {};

  return (
    <div
      onClick={() => {
        onClick();
        push(`/${clickPath}`);
      }}
      className="flex w-full cursor-pointer flex-row gap-8 px-16 py-8 text-left text-t3 hover:bg-dark-blue-10"
    >
      <div className="flex h-full min-w-[48px] flex-grow flex-col justify-center">
        {imageType === "AVATAR" ? (
          <Avatar
            imageSize={48}
            name={avatarUser?.name || ""}
            avatarUrl={avatarUser?.avatarUrl}
            isRounded
          />
        ) : (
          <Image src={iconPath || ""} width={48} height={48} alt="" />
        )}
      </div>
      <div>
        <p className={classNames({ "font-medium": isRead === false })}>
          {title}
        </p>
      </div>
    </div>
  );
};

export default AlertItem;

import { forwardRef } from "react";
import { useId } from "@reach/auto-id";

interface RadioProps extends React.InputHTMLAttributes<HTMLInputElement> {}

const Radio = forwardRef<HTMLInputElement, RadioProps>(function Radio(
  { children, ...rest },
  ref
) {
  const inputId = useId();
  return (
    <div className="flex items-center gap-x-4 text-t4 text-grey--50">
      <input
        type="radio"
        id={inputId}
        className="accent-blue-100"
        ref={ref}
        {...rest}
      />
      <label htmlFor={inputId}>{children}</label>
    </div>
  );
});

export default Radio;

// a copy from tailwind.config
const screenSizes = {
  sm: 640,
  md: 821,
  lg: 1024,
};

const IS_MOBILE_MEDIA_QUERY = `(max-width: ${screenSizes.sm}px)`;
const IS_TABLET_OR_SMALLER_MEDIA_QUERY = `(max-width: ${screenSizes.md}px)`;
const IS_HOVERABLE_MEDIA_QUERY = "(hover: hover) and (pointer: fine)";

export {
  screenSizes,
  IS_MOBILE_MEDIA_QUERY,
  IS_HOVERABLE_MEDIA_QUERY,
  IS_TABLET_OR_SMALLER_MEDIA_QUERY,
};

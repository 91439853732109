import { Loader } from "react-feather";
import classNames from "classnames";

const LoadingIndicator = ({
  className,
  size,
}: {
  className?: string;
  size?: number;
}) => {
  return (
    <Loader
      className={classNames(["animate-spin text-grey-50", className])}
      size={size}
    />
  );
};

export default LoadingIndicator;

import useDebouncedWindowSize from "hooks/useDebouncedWindowSize";
import { screenSizes } from "constants/screen";

const ITEM_WIDTH = 64;
const MIN_SPACING = 40;

const useResponsiveSidebar = ({ itemsCount }: { itemsCount: number }) => {
  const { windowSize } = useDebouncedWindowSize();

  const isDesktop = windowSize >= screenSizes.md;

  if (isDesktop) {
    return {
      noOfItemsToDisplay: 4,
      isMorePopoverShown: false,
    };
  }

  const noOfItemsToDisplay = Math.floor(
    windowSize / (ITEM_WIDTH + MIN_SPACING)
  );

  const isMorePopoverShown = noOfItemsToDisplay < itemsCount;
  return {
    noOfItemsToDisplay: isMorePopoverShown
      ? noOfItemsToDisplay - 1
      : noOfItemsToDisplay,
    isMorePopoverShown,
  };
};

export default useResponsiveSidebar;

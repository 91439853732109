import { forwardRef } from "react";

import Input, { InputProps } from "./Input";

interface CurrencyInputProps extends InputProps {}

const CurrencyInput = forwardRef<HTMLInputElement, CurrencyInputProps>(
  function CurrencyInput(props, ref) {
    return <Input leftEl="$" ref={ref} {...props} />;
  }
);

export default CurrencyInput;

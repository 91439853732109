import classNames from "classnames";
import Avatar from "components/People/Avatar";
import { AppUser } from "constants/types";

type UserListItemProps = {
  appUser: AppUser;
  size?: "s" | "l";
};

const imageSizes = {
  s: 24,
  l: 48,
};

const UserListItem: React.FC<UserListItemProps> = ({ appUser, size = "s" }) => {
  return (
    <div
      className={classNames("flex w-full gap-x-8", {
        "text-t3 leading-4": size === "l",
        "text-t5 leading-[10px]": size === "s",
      })}
    >
      <div className="flex flex-col justify-center">
        {appUser?.name && (
          <Avatar
            name={appUser.name}
            imageSize={imageSizes[size]}
            avatarUrl={appUser.avatarUrl}
            isRounded
          />
        )}
      </div>
      <div className="flex flex-col justify-center">
        <p className="my-2 font-medium">{appUser?.name}</p>
        <p className="my-2 font-medium text-grey-100">{appUser?.title}</p>
      </div>
    </div>
  );
};

export default UserListItem;

import { useUserContext } from "components/GlobalContexts/UserContext";
import { ReactElement, ReactNode } from "react";
/**
 * Used for controlling visibility of components based on scope of permissions provided to it.
 * Permissions for the customer will be automatically extracted.
 */

type CanProps = {
  children?: ReactNode;
  no?: ReactElement;
  requiredPermissions: Array<string>;
  override?: boolean;
};

const Can: React.FC<CanProps> = ({
  children,
  no,
  requiredPermissions,
  override,
}) => {
  const userContext = useUserContext();

  const hasPermissions = userContext.hasPermissions;

  const hasAccess =
    override || (hasPermissions && hasPermissions(requiredPermissions));

  return <>{hasAccess ? children : no || ""}</>;
};

export default Can;

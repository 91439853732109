import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import SWITCH_PRIMARY_ORG from "graphql/mutations/switchPrimaryOrg.graphql";
import { useOrganization } from "components/GlobalContexts/OrganizationContext";
import { Star, Loader } from "react-feather";
import { SessionKeys, sessionStore } from "utils/store";
import { useGlobalError } from "components/Error/GlobalErrorContext";

const PrimaryOrgSwitcher = ({
  id,
  checked,
}: {
  id: string;
  checked: boolean;
}) => {
  const { switchOrg, organization } = useOrganization();
  const { showApolloError } = useGlobalError();
  // storing in state because useOrganization?.organization?.id may change after switchPrimaryOrg mutation
  const [currentOrganizationId] = useState(organization?.id);

  const [switchPrimaryOrg, { loading: switchingPrimaryOrg }] = useMutation(
    SWITCH_PRIMARY_ORG,
    {
      onCompleted: () => {
        if (
          sessionStore.get(SessionKeys.CURRENT_ORG) === currentOrganizationId
        ) {
          return;
        }
        // when the primary org is changed by the mutation
        // according the public.my_current_org_id() function the useOrganization?.organization?.id might be changed
        // and this helps to keep using the org with currentOrganizationId
        switchOrg(currentOrganizationId);
      },
      onError: showApolloError,
    }
  );

  if (switchingPrimaryOrg) {
    return <Loader className="animate-spin text-grey-25" />;
  }

  if (checked) {
    return (
      <span title="Default org">
        <Star className="text-dark-blue-75" />
      </span>
    );
  }

  return (
    <button
      onClick={() => {
        switchPrimaryOrg({
          variables: {
            _orgId: id,
          },
        });
      }}
      title="Make this my default org"
    >
      <Star className="text-grey-25" />
    </button>
  );
};

export default PrimaryOrgSwitcher;

import classNames from "classnames";
import TextAvatar from "./TextAvatar";

type AvatarProps = {
  imageSize: number;
  name: string;
  avatarUrl?: string | null;
  isRounded?: boolean;
  className?: string;
};

const Avatar: React.FC<AvatarProps> = ({
  imageSize,
  name,
  avatarUrl,
  isRounded,
  className,
}) => {
  return avatarUrl ? (
    /* eslint-disable-next-line */
    <img
      src={avatarUrl}
      width={imageSize}
      height={imageSize}
      className={classNames(className, { "rounded-full": isRounded })}
    />
  ) : (
    <TextAvatar
      className={classNames(className, { "rounded-full": isRounded })}
      name={name}
      imageSize={imageSize}
    />
  );
};

export default Avatar;

import { useApolloClient } from "@apollo/client";

/**
 * Add any proceedings for logging out a user
 */
function useLogoutUser() {
  const apolloClient = useApolloClient();

  return function handleLogout() {
    window.localStorage?.clear();
    window.sessionStorage?.clear();

    apolloClient.stop();
    apolloClient.clearStore();

    /* This is usually called from an anchor tag. So return true for the href to be processed */
    return true;
  };
}

export default useLogoutUser;

import { useEffect, useState } from "react";
import { gql, useSubscription } from "@apollo/client";

import { useOrganization } from "components/GlobalContexts/OrganizationContext";

const ALERT_UNSEEN_SUBSCRIPTION = gql`
  subscription alertUnseenSubscription {
    newAlert {
      time
      organizationId
    }
  }
`;

const useHasAlert = ({
  unseenAlertExists: unseenPrior = false,
}: {
  unseenAlertExists: boolean;
}) => {
  const [unseenAlertExists, setUnseenAlertExists] = useState(unseenPrior);
  const { organization: currentOrganization } = useOrganization();

  useEffect(() => {
    setUnseenAlertExists(unseenPrior);
  }, [unseenPrior]);

  useSubscription<{ newAlert: { organizationId: string } }>(
    ALERT_UNSEEN_SUBSCRIPTION,
    {
      shouldResubscribe: true,
      onSubscriptionData: ({ subscriptionData: { data } }) => {
        if (!data) return;
        if (!currentOrganization) return;
        const {
          newAlert: { organizationId: alertOrganizationId },
        } = data;
        const { id: organizationId } = currentOrganization;
        if (organizationId === alertOrganizationId) {
          setUnseenAlertExists(true);
        }
      },
    }
  );

  return unseenAlertExists;
};

export default useHasAlert;

import classNames from "classnames";
import { FC } from "react";
import { AlertCircle } from "react-feather";

interface ErrorMessageProps {
  id?: string;
  children?: string;
}

const ErrorMessage: FC<ErrorMessageProps> = ({ id, children }) => {
  return (
    <div
      className={classNames(
        "mt-8 ml-8 mb-4 flex items-center gap-x-2 text-left text-t5 text-red-100",
        children ? "visible" : "invisible"
      )}
    >
      <AlertCircle aria-hidden="true" size={12} />
      <p id={id} role="alert" className="">
        {children}
      </p>
    </div>
  );
};

export default ErrorMessage;

import { Controller, Control, FieldValues } from "react-hook-form";
import { GroupBase } from "react-select";
import { CreatableProps } from "react-select/creatable";
import CreatableDropdown from "./CreatableDropdown";

interface ControlledCreatableDropdownProps<
  Option,
  isMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
> extends CreatableProps<Option, isMulti, Group> {
  label: string;
  errorMessage?: string;
  required?: boolean;
  name: string;
  control: Control<FieldValues, object>;
  defaultValue?: any;
}

function ControlledCreatableDropdown<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  name,
  control,
  label,
  required,
  errorMessage,
  defaultValue,
  ...rest
}: ControlledCreatableDropdownProps<Option, IsMulti, Group>) {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { ref, ...fieldRest } }) => {
        return (
          <CreatableDropdown
            label={label}
            required={required}
            errorMessage={errorMessage}
            {...fieldRest}
            {...rest}
          />
        );
      }}
    />
  );
}

export default ControlledCreatableDropdown;

import { useId } from "@reach/auto-id";
import classNames from "classnames";
import Image from "next/image";
import { FC, useState } from "react";
import { FileWithPath, useDropzone } from "react-dropzone";
import { X } from "react-feather";
import ErrorMessage from "../ErrorMessage";
import Label from "../Label";

interface FileInputProps {
  errorMessage?: string;
  label?: string;
}

const FileInput: FC<FileInputProps> = ({
  errorMessage,
  label = "Attachments",
}) => {
  const inputId = useId();
  const errorId = `error-${inputId}`;
  const [files, setFiles] = useState<FileWithPath[]>([]);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      setFiles(acceptedFiles);
    },
  });

  const removeFile = (index: number) => {
    setFiles((files) => files.filter((_, i) => i !== index));
  };

  const filesView = files.map((file: FileWithPath, index) => (
    <li key={file.path} className="text-grey flex justify-between gap-x-1">
      <p className="min-w-0 truncate">{file.path}</p>
      <button
        aria-label="Remove File"
        title="Remove File"
        onClick={() => removeFile(index)}
      >
        <X size={16} />
      </button>
    </li>
  ));

  return (
    <section className="flex w-full flex-col gap-y-2 text-t4">
      <Label label={label}>
        <div
          {...getRootProps({
            className:
              "border-2 rounded-large border-dashed border-grey-15 py-8 px-16 flex items-center",
          })}
        >
          <input {...getInputProps({ id: inputId })} />
          <div className="flex grow flex-col items-center justify-center text-t4">
            <p>Drag and Drop</p>
            <p>or</p>
            <p className="font-medium text-blue-100">Browse Files</p>
          </div>
          <Image
            src="/illustrations/upload-file.svg"
            alt=""
            height="100%"
            width="128px"
          />
        </div>
      </Label>
      <ErrorMessage id={errorId}>{errorMessage}</ErrorMessage>
      <div
        className={classNames(files.length ? "h-auto" : "h-0 overflow-hidden")}
      >
        <aside
          className={classNames(
            "flex origin-top transform flex-col gap-y-4 px-8 transition-transform",
            files.length ? "scale-y-full opacity-1" : "scale-y-0 opacity-0"
          )}
        >
          <h4 className="text-left font-medium text-grey--50">Files</h4>
          <ul className="flex w-full flex-col gap-y-2">{filesView}</ul>
        </aside>
      </div>
    </section>
  );
};

export default FileInput;

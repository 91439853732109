import { useId } from "@reach/auto-id";
import AsyncSelect, { AsyncProps } from "react-select/async";
import { GroupBase, OptionsOrGroups } from "react-select";
import { useRef } from "react";
import Control from "./Control";
import DropdownWrapper from "./DropdownWrapper";

interface AsyncDropdownProps<
  Option,
  isMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
> extends AsyncProps<Option, isMulti, Group> {
  label: string;
  errorMessage?: string;
  required?: boolean;
  hideError?: boolean;
}

function AsyncDropdown<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  label,
  required,
  errorMessage,
  loadOptions,
  defaultOptions,
  hideError = false,
  ...rest
}: AsyncDropdownProps<Option, IsMulti, Group>) {
  const inputId = useId();
  const errorId = `error-${inputId}`;

  const timerRef = useRef<NodeJS.Timeout>(setTimeout(() => {}));

  const debounceLoadOptions = (
    input: string,
    callback: (options: OptionsOrGroups<Option, Group>) => void
  ): void | Promise<OptionsOrGroups<Option, Group>> =>
    new Promise((resolve) => {
      if (loadOptions) {
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
          const options = loadOptions(input, callback);
          if (options) {
            resolve(options);
          }
        }, 300);
      } else {
        resolve(defaultOptions as OptionsOrGroups<Option, Group>);
      }
    });

  return (
    <DropdownWrapper
      label={label}
      required={required}
      errorMessage={errorMessage}
      hideError={hideError}
      errorId={errorId}
    >
      <AsyncSelect
        {...rest}
        components={{ Control, IndicatorSeparator: () => null }}
        aria-errormessage={errorId}
        aria-invalid={!!errorMessage}
        loadOptions={debounceLoadOptions}
        defaultOptions={defaultOptions}
      />
    </DropdownWrapper>
  );
}

export default AsyncDropdown;

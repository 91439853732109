import classNames from "classnames";
import Link from "next/link";
import { FC, JSXElementConstructor, SVGProps, ReactNode } from "react";

type NavItemProps = {
  name: string;
  Icon: JSXElementConstructor<SVGProps<SVGSVGElement>>;
  current: string;
  href?: string;
  className?: string;
};

type NavWrapperProps = {
  children?: ReactNode;
  isCurrent: boolean;
  className?: string;
};

export const NavWrapper: FC<NavWrapperProps> = ({
  isCurrent,
  children,
  className,
}) => (
  <div
    className={classNames(
      `mt-8 flex min-w-[64px] flex-col items-center rounded-t-full px-4 py-12 text-t5 font-medium text-white transition-colors duration-300 hover:bg-dark-blue-50 md:min-w-0 md:rounded-l-full md:rounded-tr-none`,
      {
        "bg-dark-blue-75": isCurrent,
        "text-green-100": isCurrent,
      },
      className
    )}
  >
    {children}
  </div>
);

const NavItem: FC<NavItemProps> = ({
  name,
  Icon,
  current,
  href,
  className,
}) => {
  const isCurrent = current === name;
  const NavContent = (
    <NavWrapper isCurrent={isCurrent}>
      <Icon />
      <p className="mt-4">{name}</p>
    </NavWrapper>
  );

  return href ? (
    <Link href={href}>
      <a className={className}>{NavContent}</a>
    </Link>
  ) : (
    NavContent
  );
};

export default NavItem;

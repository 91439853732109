import {
  FC,
  JSXElementConstructor,
  SVGProps,
  useState,
  ReactNode,
} from "react";
import classNames from "classnames";
import Link from "next/link";
import { Menu, X } from "react-feather";
import AccountPill from "./AccountPill";
import NavItem from "./NavItem";

interface PopoverItemWrapperProps {
  children?: ReactNode;
  isCurrent?: boolean;
}

const PopoverItemWrapper: FC<PopoverItemWrapperProps> = ({
  children,
  isCurrent,
}) => {
  return (
    <div
      className={classNames(
        "flex items-center gap-x-8 rounded-tl-xlarge rounded-bl-xlarge bg-white p-16 text-t5 font-medium text-grey--50",
        {
          "bg-green-100": isCurrent,
        }
      )}
    >
      {children}
    </div>
  );
};

interface PopoverItemProps {
  children?: ReactNode;
  href: string;
  Icon: JSXElementConstructor<SVGProps<SVGSVGElement>>;
  current: string;
  name: string;
  className?: string;
}

const PopoverItem: FC<PopoverItemProps> = ({
  Icon,
  children,
  href,
  current,
  name,
  className,
}) => {
  return (
    <li className={className}>
      <Link href={href}>
        <a>
          <PopoverItemWrapper isCurrent={current === name}>
            <Icon />
            {children}
          </PopoverItemWrapper>
        </a>
      </Link>
    </li>
  );
};

interface MorePopoverProps {
  current: string;
  user: {
    avatarUrl?: string;
    name?: string;
  };
  items: Array<{
    name: string;
    Icon: JSXElementConstructor<SVGProps<SVGSVGElement>>;
    href: string;
  }>;
}

const MorePopover: FC<MorePopoverProps> = ({ current, user, items }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <button className="block md:hidden" onClick={() => setIsOpen((o) => !o)}>
        <NavItem name="More" Icon={isOpen ? X : Menu} current={current} />
      </button>
      <div
        className={classNames(
          "fixed top-0 left-0 right-0 bottom-[72px] bg-blue-100 transition-opacity",
          isOpen ? "opacity-40" : "opacity-0",
          isOpen ? "translate-x-0" : "translate-x-full"
        )}
        aria-label="Close More Popover"
        onClick={() => setIsOpen((o) => !o)}
      />
      <div
        className={classNames(
          "fixed bottom-[calc(72px_+_12px)] right-0 z-20 transition-transform",
          isOpen ? "translate-x-0" : "translate-x-full"
        )}
      >
        <ul className="flex flex-col gap-y-12">
          {items.map((i) => {
            if (i.name === "Account") {
              return (
                <li key="Account">
                  <Link href="/account">
                    <a>
                      <PopoverItemWrapper>
                        <AccountPill {...user} />
                        <p>Account</p>
                      </PopoverItemWrapper>
                    </a>
                  </Link>
                </li>
              );
            }
            return (
              <PopoverItem
                key={i.name}
                name={i.name}
                href={i.href}
                Icon={i.Icon}
                current={current}
              >
                {i.name}
              </PopoverItem>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default MorePopover;

import classNames from "classnames";

import { components, ControlProps, GroupBase } from "react-select";

function Control<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({ children, ...props }: ControlProps<Option, IsMulti, Group>) {
  return (
    <components.Control
      className={classNames([
        "input-wrapper !rounded-full !border-0",
        {
          invalid: props.selectProps["aria-invalid"],
        },
      ])}
      {...props}
    >
      {children}
    </components.Control>
  );
}

export default Control;

import { useQuery, gql } from "@apollo/client";
import ControlledCreatableDropdown from "./ControlledCreatableDropdown";
import { Control, FieldValues } from "react-hook-form";
import { DepartmentsConnection } from "constants/types";
import { useEffect, useState } from "react";

const GET_DEPARTMENTS = gql`
  query getDepartments {
    departments(orderBy: NAME_ASC) {
      totalCount
      nodes {
        id
        name
      }
    }
  }
`;

type DepartmentDropDownProps = {
  label: string;
  required?: boolean;
  name: string;
  errorMessage?: string;
  disabled?: boolean;
  clearable?: boolean;
  control: Control<FieldValues, object>;
  defaultValue: any;
  placeholder?: string;
};

const DepartmentDropdown: React.FC<DepartmentDropDownProps> = ({
  label,
  required = false,
  name,
  errorMessage,
  disabled,
  clearable,
  control,
  placeholder = "Select a department",
  ...rest
}) => {
  const { data } = useQuery<{ departments: DepartmentsConnection }>(
    GET_DEPARTMENTS
  );
  const [options, setOptions] = useState<
    Array<{ label: string; value: string }>
  >([]);

  useEffect(() => {
    if (data?.departments?.nodes?.length) {
      const departments = data?.departments.nodes.filter(
        (department) => !!department?.name
      );
      setOptions(
        departments.map((department) => ({
          label: department?.name || "",
          value: department?.name || "",
        }))
      );
    }
  }, [data, setOptions]);

  return (
    <ControlledCreatableDropdown
      control={control}
      label={label}
      required={required}
      options={options}
      name={name}
      errorMessage={errorMessage}
      isDisabled={disabled}
      isClearable={clearable}
      placeholder={placeholder}
      {...rest}
    />
  );
};

export default DepartmentDropdown;

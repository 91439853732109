import { useEffect, useRef, useState } from "react";

import useOnClickOutside from "hooks/useClickOutside";
import useOnKeypress from "hooks/useOnKeypress";
import { useRouter } from "next/router";
import classNames from "classnames";

function Popover({
  children,
  onClose,
  pointyClassNames,
  popoverClassNames = "bottom-[8px] left-[100px]",
}: {
  children: React.ReactNode;
  onClose: Function;
  pointyClassNames: string;
  popoverClassNames?: string;
}) {
  const wrapperRef = useRef(null);
  useOnClickOutside(wrapperRef, onClose);
  useOnKeypress(["Escape"], onClose);
  const router = useRouter();
  const [initialPathName] = useState(router.pathname);

  useEffect(() => {
    if (initialPathName !== router.pathname) {
      onClose();
    }
    // onClose when pathname changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.pathname]);

  return (
    <>
      <div className="fixed left-0 top-0 z-10 h-screen w-screen bg-dark-blue-100 opacity-50" />
      <div
        className={classNames([
          "absolute z-10 h-16 w-16 rotate-45 bg-white",
          pointyClassNames,
        ])}
      />
      <div
        role="dialog"
        className={classNames([
          "absolute z-10 rounded-[2rem] bg-white",
          popoverClassNames,
        ])}
        ref={wrapperRef}
      >
        {children}
      </div>
    </>
  );
}

export default Popover;

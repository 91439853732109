import { useQuery, gql } from "@apollo/client";
import ControlledDropdown from "./ControlledDropdown";
import { Control, FieldValues } from "react-hook-form";
import { RolesConnection } from "constants/types";
import { useEffect, useState } from "react";
import { compareTwoStrings } from "string-similarity";

const GET_ROLES = gql`
  query getRoles {
    roles(orderBy: NAME_ASC) {
      totalCount
      nodes {
        id
        name
      }
    }
  }
`;

type RoleDropdownProps = {
  label: string;
  required?: boolean;
  name: string;
  errorMessage?: string;
  disabled?: boolean;
  clearable?: boolean;
  control: Control<FieldValues, object>;
  defaultValue: any;
};

const RoleDropdown: React.FC<RoleDropdownProps> = ({
  label,
  required = false,
  name,
  errorMessage,
  disabled,
  clearable,
  control,
  ...rest
}) => {
  const { data } = useQuery<{ roles: RolesConnection }>(GET_ROLES);
  const [options, setOptions] = useState(data?.roles.nodes || []);

  useEffect(() => {
    setOptions(data?.roles.nodes || []);
  }, [data, setOptions]);

  const handleInputChange = (newValue: string) => {
    if (newValue && options.length) {
      setOptions(
        options.slice().sort((a, b) => {
          return (
            compareTwoStrings(b?.name || "", newValue) -
            compareTwoStrings(a?.name || "", newValue)
          );
        })
      );
    } else {
      setOptions(data?.roles.nodes || []);
    }
  };

  return (
    <ControlledDropdown
      control={control}
      label={label}
      required={required}
      options={options}
      getOptionValue={(option) => option?.id}
      getOptionLabel={(option) => option?.name || ""}
      name={name}
      errorMessage={errorMessage}
      isDisabled={disabled}
      isClearable={clearable}
      placeholder="Select Role"
      onInputChange={handleInputChange}
      {...rest}
    />
  );
};

export default RoleDropdown;

import { getInitials } from "../../../utils/formatText";

type TextAvatarProps = {
  name: string;
  imageSize: number;
  style?: React.CSSProperties;
  className?: string;
};

const viewboxSizes = {
  s: 192,
  m: 96,
  l: 48,
};

const getTextSize = (imageSize: number) => {
  if (imageSize < 48) return "l";
  if (imageSize < 128) return "m";
  return "s";
};

const TextAvatar: React.FC<TextAvatarProps> = ({
  name,
  imageSize,
  style,
  className,
}) => {
  const size = viewboxSizes[getTextSize(imageSize)];
  const fontSize = 24;
  // If this component is ever exported as a svg image the font will need to be included as a base64 data url
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={imageSize}
      height={imageSize}
      viewBox={`0 0 ${size} ${size}`}
      style={style}
      className={className}
    >
      <rect width={size} height={size} x={0} y={0} fill="#61649A" />
      <text
        textAnchor="middle"
        x={size / 2}
        // Todo: adjust y position when font size is changed
        y={size / 2 + 9}
        style={{ fontSize: fontSize }}
        fill="#ffffff"
      >
        {getInitials(name)}
      </text>
    </svg>
  );
};

export default TextAvatar;

import { FC, useState } from "react";
import Link from "next/link";
import { useQuery } from "@apollo/client";
import { Settings, Users, List, BarChart2 } from "react-feather";

import GET_CURRENT_USER from "graphql/queries/getCurrentUser.graphql";
import AccountPopover from "components/Account/AccountPopover";
import { Authority, OxleyLogo } from "components/Icons";
import { useUserContext } from "components/GlobalContexts/UserContext";
import Can from "components/AccessControl/Can";

import NavItem, { NavWrapper } from "./NavItem";
import Alerts from "./Alerts";
import MorePopover from "./MorePopover";
import AccountPill from "./AccountPill";
import useResponsiveSidebar from "./useResponsiveSidebar";

type SideNavigationProps = {
  current: string;
};

const SideNavigation: FC<SideNavigationProps> = ({ current }) => {
  const userContext = useUserContext();

  const { data } = useQuery(GET_CURRENT_USER, {
    fetchPolicy: "cache-and-network",
  });

  const hasPermissions = userContext.hasPermissions;

  const { avatarUrl, name } = data?.myAppUser || {};

  const items = [
    {
      name: "Queue",
      Icon: List,
      href: "/",
    },
    {
      name: "Authority",
      Icon: Authority,
      href: "/authority",
    },
    {
      name: "People",
      Icon: Users,
      href: "/people/chart",
    },
    {
      name: "Reports",
      Icon: BarChart2,
      href: "/reports",
    },
    {
      name: "Admin",
      Icon: Settings,
      href: "/admin/users",
      permissions: ["create:user", "delete:user"],
    },
    {
      name: "Account",
      Icon: Settings,
      href: "",
    },
  ].filter((e) => hasPermissions && hasPermissions(e?.permissions ?? []));

  const [isPopoverVisible, setIsPopoverVisbile] = useState(false);
  const onClose = () => setIsPopoverVisbile(false);
  const { noOfItemsToDisplay, isMorePopoverShown } = useResponsiveSidebar({
    itemsCount: items.length,
  });

  const itemsInPopover = items.length - noOfItemsToDisplay;
  const morePopOver = (
    <MorePopover
      current={current}
      user={{ avatarUrl, name }}
      items={items.slice(-itemsInPopover)}
    />
  );

  return (
    <nav className="relative isolate z-10 flex max-h-full bg-dark-blue-100 text-center text-white md:w-[96px] md:flex-col">
      {isPopoverVisible && <AccountPopover onClose={onClose} />}
      <div className="flex flex-grow justify-between px-8 md:ml-4 md:flex-col md:justify-start md:px-0">
        <div className="hidden py-8 md:block">
          <Link href="/">
            <a>
              <OxleyLogo style={{ margin: "auto" }} />
            </a>
          </Link>
        </div>
        {items.slice(0, noOfItemsToDisplay).map((i) => {
          if (i.name === "Account") {
            return (
              <button
                key="Account"
                onClick={() => {
                  setIsPopoverVisbile(true);
                }}
              >
                <NavWrapper isCurrent={current === "Account"}>
                  <AccountPill avatarUrl={avatarUrl} name={name} />
                  <p>Account</p>
                </NavWrapper>
              </button>
            );
          } else {
            return <NavItem current={current} key={i.name} {...i} />;
          }
        })}
        {isMorePopoverShown && morePopOver}
      </div>
      <div className="mb-8 hidden md:flex md:flex-col md:justify-end">
        <Alerts current={current} unseenAlertExists={data?.unseenAlertExists} />
        <Can requiredPermissions={["create:user", "delete:user"]}>
          <NavItem
            name="Admin"
            Icon={Settings}
            current={current}
            href="/admin/users"
          />
        </Can>
        <button
          onClick={() => {
            setIsPopoverVisbile(true);
          }}
        >
          <NavWrapper isCurrent={current === "Account"}>
            <AccountPill avatarUrl={avatarUrl} name={name} />
            <p>Account</p>
          </NavWrapper>
        </button>
      </div>
    </nav>
  );
};

export default SideNavigation;

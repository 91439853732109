import { useState, useEffect } from "react";
import { debounce } from "lodash";

const useDebouncedWindowSize = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  useEffect(() => {
    const setSize = debounce(
      () => {
        setWindowSize(window.innerWidth);
      },
      400,
      {
        leading: false,
        trailing: true,
      }
    );

    window.addEventListener("resize", setSize);
    return () => window.removeEventListener("resize", setSize);
  }, []);

  return { windowSize };
};

export default useDebouncedWindowSize;

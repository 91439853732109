import classNames from "classnames";
import { FC, ReactNode } from "react";

interface LabelProps {
  children?: ReactNode;
  label: string;
  required?: boolean;
}

const Label: FC<LabelProps> = ({ children, label, required = false }) => {
  return (
    <label className="flex w-full flex-col gap-y-8 text-left text-t5">
      {label && (
        <p
          className={classNames("text-left font-medium text-grey--50", {
            "after:ml-2 after:text-red-100 after:content-['*']": required,
          })}
        >
          {label}
        </p>
      )}

      {children}
    </label>
  );
};

export default Label;

import { FC, useState } from "react";
import { Bell } from "react-feather";

import AlertPopover from "components/Alert/AlertPopover";
import useHasAlert from "hooks/useHasAlert";
import NavItem from "./NavItem";

interface AlertsProps {
  current: string;
  unseenAlertExists?: boolean;
}

const Alerts: FC<AlertsProps> = ({ current, unseenAlertExists = false }) => {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const hasAlert = useHasAlert({ unseenAlertExists });

  const onClose = () => setIsPopoverVisible(false);

  return (
    <>
      {isPopoverVisible && <AlertPopover onClose={onClose} />}
      <button className="relative" onClick={() => setIsPopoverVisible(true)}>
        <NavItem name="Alerts" Icon={Bell} current={current} />
        {hasAlert && (
          <div className="absolute top-12 right-32">
            <span className="inline-block h-12 w-12 rounded-full bg-red-100" />
          </div>
        )}
      </button>
    </>
  );
};

export default Alerts;

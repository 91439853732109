import AsyncDropdown from "./AsyncDropdown";
import { Controller, Control } from "react-hook-form";
import { GroupBase } from "react-select";
import { AsyncProps } from "react-select/async";

interface AsyncControlledDropdownProps<
  Option,
  isMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
> extends AsyncProps<Option, isMulti, Group> {
  label: string;
  errorMessage?: string;
  required?: boolean;
  name: string;
  control: Control;
  defaultValue: any;
  hideError?: boolean;
}

function AsyncControlledDropdown<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  name,
  control,
  label,
  required,
  errorMessage,
  defaultValue,
  onChange = (v) => v,
  ...rest
}: AsyncControlledDropdownProps<Option, IsMulti, Group>) {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({
        field: { ref, onChange: onControllerChange, ...fieldRest },
      }) => (
        <AsyncDropdown
          label={label}
          required={required}
          errorMessage={errorMessage}
          onChange={(newValue, actionMeta) => {
            onChange(newValue, actionMeta);
            onControllerChange(newValue, actionMeta);
          }}
          {...fieldRest}
          {...rest}
        />
      )}
    />
  );
}

export default AsyncControlledDropdown;

import { gql, useMutation, useQuery } from "@apollo/client";
import { Alert } from "constants/types";
import Image from "next/image";
import Popover from "components/Popover";
import LoadingIndicator from "components/LoadingIndicator";
import dayjs from "dayjs";
import AlertItem from "./AlertItem";
import { useMemo } from "react";
import isToday from "dayjs/plugin/isToday";
import isYesterday from "dayjs/plugin/isYesterday";
import useMatchMedia from "hooks/useMatchMedia";
import {
  IS_MOBILE_MEDIA_QUERY,
  IS_TABLET_OR_SMALLER_MEDIA_QUERY,
} from "constants/screen";

dayjs.extend(isToday);
dayjs.extend(isYesterday);

const formatDate = (date: string) => {
  if (dayjs(date).isToday()) {
    return "Today";
  }
  if (dayjs(date).isYesterday()) {
    return "Yesterday";
  }
  return date;
};

const GET_ALERTS = gql`
  query getAlerts {
    myAppUser {
      alerts(orderBy: CREATED_AT_DESC) {
        nodes {
          id
          title
          clickPath
          imageType
          iconPath
          isRead
          createdAt
          avatarUser {
            id
            name
            avatarUrl
          }
        }
      }
    }
  }
`;

const MARK_ALERTS_AS_READ = gql`
  mutation markAlertsAsRead {
    markAlertsAsRead(input: { clientMutationId: "" }) {
      clientMutationId
    }
  }
`;

const AccountPopover = ({ onClose }: { onClose: Function }) => {
  const { data, loading } = useQuery(GET_ALERTS);
  const alerts = data?.myAppUser?.alerts?.nodes;

  const alertGroupedByDate = useMemo(() => {
    return (alerts || []).reduce(
      (
        acc: {
          [key: string]: Array<Alert>;
        },
        i: Alert
      ) => {
        const formattedDate = dayjs(i.createdAt).format("MM/DD/YYYY");
        acc[formattedDate] = [...(acc[formattedDate] || []), i];
        return acc;
      },
      {}
    );
  }, [alerts]);

  const [markAsRead] = useMutation(MARK_ALERTS_AS_READ);

  const handleClose = () => {
    markAsRead();
    onClose();
  };

  const isMobile = useMatchMedia(IS_MOBILE_MEDIA_QUERY);
  const isTablet = useMatchMedia(IS_TABLET_OR_SMALLER_MEDIA_QUERY);

  const getPopoverClassNames = () => {
    // ["pointy", "popover"]
    if (isMobile) {
      return [
        "hidden",
        "text-center w-[calc(100%_-20px)] left-[30px] top-[60px]",
      ];
    }
    if (isTablet) {
      return ["top-[55px]", "right-[10px] top-[60px]"];
    }
    return ["bottom-[180px] left-[92px]", "bottom-[8px] left-[100px]"];
  };

  const [pointyClassNames, popoverClassNames] = getPopoverClassNames();

  return (
    <Popover
      onClose={handleClose}
      pointyClassNames={pointyClassNames}
      popoverClassNames={popoverClassNames}
    >
      <div className="my-24 w-[320px] text-grey--50 ">
        <p className="text-center text-t2 font-medium">Alerts</p>
        <div className="mt-16 max-h-[75vh] min-h-[250px] w-full overflow-y-auto">
          {loading && <LoadingIndicator />}
          {alerts?.length === 0 && (
            <>
              <p className="mb-24 text-t3 text-grey-75 ">
                Nothing to see here!
              </p>
              <div>
                <Image
                  src="/illustrations/empty-alert.svg"
                  alt=""
                  width={320}
                  height={240}
                />
              </div>
            </>
          )}
          {Object.keys(alertGroupedByDate)
            .sort()
            .reverse()
            .map((date) => (
              <>
                <div className="my-4 ml-8">
                  <span className="flex h-[28px] w-full items-center rounded-l-[16px] bg-dark-blue-10 pl-16 text-t5 font-medium">
                    {formatDate(date)}
                  </span>
                </div>
                {alertGroupedByDate[date].map((alert: Alert) => {
                  return (
                    <AlertItem
                      key={alert.id}
                      alert={alert}
                      onClick={handleClose}
                    />
                  );
                })}
              </>
            ))}
        </div>
      </div>
    </Popover>
  );
};

export default AccountPopover;
